<script setup>
import { defineExpose, onMounted, ref } from 'vue'

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue'])
const isOpen = ref(false)
const scrollY = ref(0)

const windowIsScrolled = computed(() => scrollY.value > 0)

if (typeof window !== 'undefined') {
  window.addEventListener('scroll', () => {
    scrollY.value = window.scrollY
  })
}

function toggle() {
  isOpen.value = !isOpen.value
  emit('update:modelValue', isOpen.value)
}

defineExpose({
  toggle,
})
</script>

<template>
  <div id="hamburger-wrapper" @click="toggle">
    <div id="hamburger" :class="{ open: isOpen }">
      <span :class="{ 'bg-black': !isOpen && windowIsScrolled, 'bg-white': isOpen || !windowIsScrolled }" />
      <span :class="{ 'bg-black': !isOpen && windowIsScrolled, 'bg-white': isOpen || !windowIsScrolled }" />
      <span :class="{ 'bg-black': !isOpen && windowIsScrolled, 'bg-white': isOpen || !windowIsScrolled }" />
    </div>
  </div>
</template>

<style scoped>
#hamburger-wrapper {
  @apply h-auto self-stretch flex items-center justify-center cursor-pointer pt-[10px] md:pt-0;

  &:has(#hamburger.open) {
    @apply p-0;
  }

  #hamburger {
    @apply relative transform rotate-0 scale-x-[0.4] md:scale-100;
    width: 60px;
    height: 20px;
    transition: 0.5s ease-in-out;

    span {
      @apply w-full h-[2px] md:h-[3px] block absolute opacity-100 left-0 transform rotate-0;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        @apply top-0;
        transform-origin: left center;
      }

      &:nth-child(2) {
        @apply top-[4px] md:top-[6px];
        transform-origin: left center;
      }

      &:nth-child(3) {
        @apply top-[8px] md:top-[12px];
        transform-origin: left center;
      }
    }

    &.open {
      @apply scale-y-[0.4] md:scale-100;

      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: -3px;
          left: 8px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 39px;
          left: 8px;
        }
      }
    }
  }
}
</style>
